@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
.App {
    text-align: center;
    font-family: "Nunito", sans-serif;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

body {
    font-family: "Nunito", sans-serif;
    margin: 0;
    padding: 0;
}

main {
    display: inline;
    position: relative;
    height: 87vh;
    width: 100%;
}

main::before {
    content: "";
    display: block;
    background-image: url(assets/Background-img.jpg);
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(13, 12, 12, 0.8);
    opacity: 0.3;
    /* filter: blur(3px); */
    z-index: -1;
}
